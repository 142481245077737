import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
👋Hi there
    </Text>
    <p>{`Srishilesh holds a bachelor's degree in Computer Science & Engineering. He has 2 yrs experience working at Mr.Cooper Group Inc. (Nationstar Mortgage Holdings) as Software Engineer I (Full-stack MLE).`}</p>
    <p>{`With his passion towards AI & blockchain, he has contributed to several projects & has participated in 20+ hackathons. His aspirations as an entrepreneur gives him a right mix of skills to understand technology & business side of things. Lately, he has been actively learning about machine learning, blockchain, startups & finance.`}</p>
    <p>{`You can learn more about me `}<a parentName="p" {...{
        "href": "/about"
      }}>{`here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      